import { GrClose } from 'react-icons/gr';
import { useFormat } from 'helpers/hooks/useFormat';

export const RetrieveUsername = ({
  resetUsername,
  toForgotPassword,
  toForgotUsername,
  retrieve,
  retrievePasswordClick,
  inputRef,
  handleInputFocus,
  handleInputBlur,
  maskPhoneNumber,
  customer,
  maskEmail,
  resendPasswordResetForUser,
  newUserName,
  resetRadioButtons,
  setReceivedOTP,
  isUserLoggedIn,
  loginApiResponse,
  handleOtpRequestForLoggedInUser,
  handleLogoutAndCloseModal,
  account,
}) => {
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  console.info('Inside RetrieveUsername to check isUserLoggedIn', isUserLoggedIn);
  return (
    <div className="w-full">
      {/* Header Section */}
      {!isUserLoggedIn ? (
        <div className="mb-8">
          <h3
            id={resetUsername ? 'resetUsernameLabel' : 'resetPasswordLabel'}
            className="absolute top-10 text-lg font-semibold text-black-900"
          >
            {formatAccountMessage({
              id: resetUsername ? 'Forgot.username' : 'Forgot.password',
              defaultMessage: resetUsername ? 'Retrieve Username' : 'Reset Password',
            })}
          </h3>
        </div>
      ) : (
        <div className="mb-8">
          <h3 id="2FAuthenticationLabel" className="absolute top-10 text-lg font-semibold text-black-900">
            {formatAccountMessage({
              id: 'Two.FA',
              defaultMessage: '2F Authentication',
            })}
          </h3>
        </div>
      )}
      {/* Close Button */}
      <button
        className="absolute right-6 top-10"
        onClick={() => {
          if (isUserLoggedIn) {
            resetRadioButtons();
            handleLogoutAndCloseModal();
          } else if (!resetUsername) {
            toForgotPassword();
          } else {
            toForgotUsername();
          }
        }}
        aria-label="Close"
        type="button"
      >
        <GrClose />
      </button>
      {/* Main Content */}
      <div className="flex-col py-10 text-center">
        <h3 className="mb-4 text-lg font-semibold text-black-900" id="retrieveMethodLabel">
          {!isUserLoggedIn &&
            formatAccountMessage({
              id: resetUsername ? 'retrieve-method.username' : 'retrieve-method.password',
              defaultMessage: resetUsername
                ? 'How would you like to retrieve your username?'
                : 'How would you like to retrieve your password?',
            })}
        </h3>
        <p>
          {formatAccountMessage({
            id: 'verification.code',
            defaultMessage:
              'We will send a temporary verification code to the mobile number or email address that is linked to your account.',
          })}
        </p>
      </div>
      {/* Retrieve Options */}
      {(account?.PhoneNumber || account?.profilePhone || loginApiResponse?.profilePhone || customer?.PhoneNumber) && (
        <div className="mb-4 flex items-center">
          <input
            id="default-radio-1"
            type="radio"
            value=""
            checked={retrieve?.isSms}
            name="phone"
            onChange={retrievePasswordClick}
            className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-black-600 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-black-600"
            aria-checked={retrieve?.isSms}
            aria-labelledby="retrieveMethodLabel"
            ref={inputRef}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
          />
          <label htmlFor="default-radio-1" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
            {formatAccountMessage({
              id: 'message.phonenumber',
              defaultMessage: 'Text Message to phone number',
            })}{' '}
            {maskPhoneNumber(
              loginApiResponse?.profilePhone || account?.phoneNumber || account?.profilePhone || customer?.PhoneNumber,
            )}
          </label>
        </div>
      )}
      <div className="flex items-center">
        <input
          id="default-radio-2"
          type="radio"
          value={account}
          name="email"
          className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-black-600 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-black-600"
          checked={retrieve?.isEmail}
          onChange={retrievePasswordClick}
          aria-checked={retrieve?.isEmail}
          aria-labelledby="retrieveMethodLabel"
          ref={inputRef}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
        />
        <label htmlFor="default-radio-2" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
          {formatAccountMessage({
            id: 'message.email',
            defaultMessage: 'Email to email address',
          })}{' '}
          {maskEmail(loginApiResponse?.email || account?.Email || account?.email || customer?.Email)}
        </label>
      </div>
      {/* Action Buttons */}
      <div className="flex justify-around pt-14">
        <button
          style={{ width: 'auto' }}
          className="w-1/4 rounded-sm bg-blue-900 px-4 py-2 text-white disabled:bg-gray-200 sm:w-auto"
          onClick={() => {
            if (isUserLoggedIn) {
              handleOtpRequestForLoggedInUser();
            } else if (!resetUsername) {
              resendPasswordResetForUser();
            } else {
              newUserName();
            }
          }}
          disabled={!retrieve?.isSms && !retrieve?.isEmail}
          aria-labelledby={resetUsername ? 'resetUsernameLabel' : 'resetPasswordLabel'}
          type="button"
        >
          {formatAccountMessage({ id: 'continue.button', defaultMessage: 'Continue' })}
        </button>
        <button
          style={{ width: 'auto' }}
          className="w-1/4 rounded-sm border border-gray-600 bg-gray-100 px-4 py-2 text-black-600"
          onClick={() => {
            if (isUserLoggedIn) {
              resetRadioButtons();
              handleLogoutAndCloseModal();
            } else if (!resetUsername) {
              toForgotPassword();
            } else {
              toForgotUsername();
              resetRadioButtons();
              setReceivedOTP('');
            }
          }}
          aria-labelledby={resetUsername ? 'resetUsernameLabel' : 'resetPasswordLabel'}
          type="button"
        >
          {formatAccountMessage({ id: 'cancel', defaultMessage: 'Cancel' })}
        </button>
      </div>
    </div>
  );
};
