import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Transition } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/solid';
import { GrClose } from 'react-icons/gr';
import { mutate } from 'swr';
import InformationRequired from 'components/commercetools-ui/aafes/Modals/informationRequired';
import exchangeLoader from 'components/gif/loading-indicator.gif';
import { GA_ACCOUNT, GA_FORM_COMPLETE, GA_LOGIN, GA_SIGN_IN } from 'helpers/constants/aafes';
import { useFormat } from 'helpers/hooks/useFormat';
import { logEventToDataLayer } from 'helpers/utils/analytics';
import { useAccount, useCart } from 'frontastic';
import {
  compareUserNameOTPWithToken,
  logout,
  requestPasswordReset,
  requestResetUsername,
  resetPassword,
  updateCompareOTP,
  verifySSN,
  loginSetAccountToSession,
} from 'frontastic/actions/account';
import { DefaultLoginPage } from './defaultLoginPage';
import { ForgotUserName } from './forgotUserName';
import { RetrieveUsername } from './retrieveUserName';
import { RetryPassword } from './retryPassword';
import { SendResetCode } from './sendResetCode';
import { SentUserName } from './sentUserName';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

export interface LoginModalContentProps {
  closeModal: any;
  loginCallBack: any;
  continueAsGuestEligible?: boolean;
  closeFlyout: any;
  closeAccountFlyout: any;
  sectionRef: any;
  inputRef?: any;
  handleInputFocus?: any;
  handleInputBlur?: any;
  handleForgotCredentials?: any;
  isRememberMeSessionExpired?: boolean;
  triggerRetrieveUsername?: boolean;
  account?: any;
  isProfileUpdate?: boolean;
  updateAccountData?: any;
  isPasswordChange?: boolean;
  passwordChangeData?: any;
  changeAccountPassword?: any;
}
function LoginModalContent({
  closeModal,
  loginCallBack = () => {},
  continueAsGuestEligible = false,
  closeFlyout,
  closeAccountFlyout,
  sectionRef,
  inputRef,
  handleInputFocus,
  handleInputBlur,
  handleForgotCredentials,
  isRememberMeSessionExpired = false,
  triggerRetrieveUsername,
  account,
  isProfileUpdate,
  updateAccountData,
  isPasswordChange,
  passwordChangeData,
  changeAccountPassword,
}: LoginModalContentProps) {
  const { login } = useAccount();
  const { studioConfig: projectConfiguration } = useCart();
  const router = useRouter();
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
  const [passwordShown, setPasswordShown] = useState(false);
  const [usernameError, setUsernameError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [authenticationError, setAuthenticationError] = useState(false);
  const [showDelayedRecordsMsg, setShowDelayedRecordsMsg] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    username: isRememberMeSessionExpired ? account?.email ?? account?.userName : '',
    password: '',
    rememberMe: false,
    is2FA: true,
  });
  const [signIn, setSignIn] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [informationRequired, setInformationRequired] = useState(false);
  const [loginApiResponse, setLoginApiResponse] = useState(undefined);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [isLoginOtpSent, setIsLoginOtpSent] = useState(false);
  const [otpResponse, setOtpResponse] = useState(undefined);
  const [deviceUuid, setDeviceUuid] = useState(null);

  function handleInformationRequired() {
    setInformationRequired(false);
  }
  const [customerData, setCustomerData] = useState({ ssn: '', day: '', month: '', year: '', lastName: '', dob: '' });
  const [retrieve, setRetrieve] = useState({ cidNumber: '', email: '', isEmail: false, isSms: false });
  //success
  const [success, setSuccess] = useState('');
  const [isEmpty, setIsEmpty] = useState(false);
  const [showSsn, setShowSsn] = useState(false);
  const [receiveOTP, setReceiveOTP] = useState(undefined);
  const [updateUsername, setUpdateUsername] = useState(undefined);
  const [updatePassword, setUpdatePassword] = useState(undefined);
  //processing...
  const [showPassword, setShowPassword] = useState(false);
  const [forgotUsername, setForgotUsername] = useState(false);
  const [resetUsername, setResetUSername] = useState(false);
  const [retrieveUsername, setRetrieveUsername] = useState(false);
  const [sendCode, setSendCode] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [sentUsername, setSentUsername] = useState(false);
  const [userOtp, setUserOtp] = useState(undefined);
  const [errorInvalidSsn, setErrorInvalidSsn] = useState(false);
  const [retryPassword, setRetryPassword] = useState(false);
  const [invalidSsn, setInvalidSsn] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const [otpSentAlert, setOtpSentAlert] = useState<boolean>(false);
  const [verificationCodeSent, setVerificationCodeSent] = useState(false);
  const [sendCodeClicked, setSendCodeClicked] = useState(false);
  //attempting to request a password reset
  const [resendPasswordReset, setResendPasswordReset] = useState(false);
  //not on default login modal
  //Password is visible while typing
  const [customer, setCustomer] = useState(undefined);
  const [newPassword, setNewPassword] = useState('');
  const [receivedOTP, setReceivedOTP] = useState('');
  const [passwordRequirements, setShowPasswordRequirements] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState('');

  //get back to login modal
  const backToLogin = () => {
    if (
      handleForgotCredentials &&
      (handleForgotCredentials?.isForgotUserName || handleForgotCredentials?.isForgotPassword)
    ) {
      closeModal();
    } else {
      setResendPasswordReset(false);
      setSendCode(false);
      setForgotUsername(false);
      setRetryPassword(false);
      setSentUsername(false);
      setResetUSername(false);
      setRetrieveUsername(false);
      setInvalidSsn(false);
      setError('');
    }
  };

  const toForgotUsername = () => {
    setResetUSername(true);
    setForgotUsername(true);
    setResendPasswordReset(true);
    setRetrieveUsername(false);
    setSendCode(false);
    setSentUsername(false);
    setRetryPassword(false);
    setInvalidSsn(false);
    setCustomerData({ day: '', month: '', year: '', ssn: '', lastName: '', dob: '' });
    setAuthenticationError(false);
    setError('');
    setResErrorMsg('');
    setVerificationCodeSent(false);
  };

  const toForgotPassword = () => {
    setForgotUsername(true);
    setResetUSername(false);
    setResendPasswordReset(true);
    setRetrieveUsername(false);
    setSendCode(false);
    setSentUsername(false);
    setRetryPassword(false);
    setInvalidSsn(false);
    setCustomerData({ day: '', month: '', year: '', ssn: '', lastName: '', dob: '' });
    setAuthenticationError(false);
    setError('');
    setResErrorMsg('');
    setVerificationCodeSent(false);
  };
  const handleLogoutAndCloseModal = () => {
    setRetrieve({
      cidNumber: '',
      email: '',
      isEmail: false,
      isSms: false,
    });
    setIsUserLoggedIn(false);
    setLoginApiResponse(undefined);
    setIsLoginOtpSent(false);
    setReceivedOTP('');
    setError('');
    if (loginApiResponse?.accountId) {
      logout(loginApiResponse.accountId);
    }
    closeModal();
  };

  const toRetrieveUsername = () => {
    setForgotUsername(false);
    setResendPasswordReset(false);
    setRetrieveUsername(true);
    setSendCode(false);
    setSentUsername(false);
    setRetryPassword(false);
    setError('');
  };

  const handleNameChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (name === 'lastName') {
      const regex = /^\s*\p{L}[-'\\. \p{L}]*\s*$/u;
      if (value.length > 0 && !regex.test(value)) {
        return;
      }
      value = value.replace(/[^\p{L} \-'\\.\\]/gu, '');
    }
    setCustomerData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleResetPassword = (event) => {
    const enteredPassword = event.target.value;
    setErrMessage('');
    setShowPasswordRequirements(true);
    // Update the password state
    setNewPassword(enteredPassword);
    setErrorMessage('');
  };

  function maskPhoneNumber(phoneNumber) {
    if (phoneNumber === ' ' || !phoneNumber) return '';
    const last4Digits = phoneNumber.slice(-4);
    const masked = '*'.repeat(phoneNumber.length - 4) + last4Digits;
    return masked;
  }
  function maskEmail(email) {
    if (!email) return '';
    const [username, domain] = email.split('@');
    const maskedUsername = username.slice(0, 3) + '***';
    return maskedUsername + '@' + domain;
  }

  //   verify ssn
  const handleVerifySSN = async () => {
    if (
      customerData.ssn === '' ||
      customerData.day === '' ||
      customerData.month === '' ||
      customerData.year === '' ||
      customerData.lastName.trim() === ''
    ) {
      setIsEmpty(true);
      return;
    }
    try {
      const data = {
        ssn: customerData.ssn,
        dobYear: parseInt(customerData.year),
        dobMonth: customerData.month,
        dobDay: customerData.day,
        lastName: customerData.lastName.trim().replace(/\s+/g, ' '),
      };
      const verifyResponse = await verifySSN(data);
      setCustomer(verifyResponse);
      if (verifyResponse.errorCode && customerData.ssn.length === 9) {
        router.push('/retrieveusernamefailure');
        return;
      }
      //Needs testing
      if (verifyResponse && !verifyResponse.errorCode) {
        const formData = {
          form_name: resetUsername ? 'Forgot Username' : 'Reset Password',
          content_id: 'Account',
        };
        logEventToDataLayer(GA_FORM_COMPLETE, formData);
      }
    } catch (err) {
      console.log('Error:', err);
    }
  };

  const resetRadioButtons = () => {
    setRetrieve({
      cidNumber: '',
      email: '',
      isEmail: false,
      isSms: false,
    });
  };
  useEffect(() => {
    if (customer?.CID) {
      setForgotUsername(false);
      setRetrieveUsername(true);
    } else {
      if (customer?.errorCode) {
        setInvalidSsn(true);
        setErrorInvalidSsn(true);
        // closeModal();
      }
    }
  }, [customer]);

  useEffect(() => {
    console.log('triggerRetrieveUsername:::', triggerRetrieveUsername, isUserLoggedIn);
    if (triggerRetrieveUsername) {
      setIsUserLoggedIn(true);
    }
  }, [triggerRetrieveUsername]);

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof navigator !== 'undefined') {
      import('device-uuid').then(({ DeviceUUID }) => {
        const du = new DeviceUUID().parse();
        const dua = [
          du.language,
          du.platform,
          du.os,
          du.cpuCores,
          du.isAuthoritative,
          du.silkAccelerated,
          du.isKindleFire,
          du.isDesktop,
          du.isMobile,
          du.isTablet,
          du.isWindows,
          du.isLinux,
          du.isLinux64,
          du.isMac,
          du.isiPad,
          du.isiPhone,
          du.isiPod,
          du.isSmartTV,
          du.pixelDepth,
          du.isTouchScreen,
        ];
        const uuid = du.hashMD5(dua.join(':'));
        setDeviceUuid(uuid);
      });
    }
  }, []);

  const checkDeviceMatch = (): boolean => {
    const lastLoginDeviceId = localStorage.getItem('deviceId');
    console.log('lastLoginDeviceId', lastLoginDeviceId);
    if (lastLoginDeviceId && lastLoginDeviceId.toLowerCase() === deviceUuid.toLowerCase()) {
      console.log('same deviceId:::', lastLoginDeviceId);
      return true;
    } else {
      console.log('different deviceId:::', lastLoginDeviceId);
      return false; // Return false when device UUID doesn't match and is set to localStorage
    }
  };

  const retrievePasswordClick = (event) => {
    const { name, checked } = event.target;
    setRetrieve((prevRetrieve) => ({
      ...prevRetrieve,
      [name]: checked,
      isEmail: name === 'email',
      isSms: name === 'phone',
      cidNumber: customer?.CID,
      email: customer?.Email,
    }));
  };

  useEffect(() => {
    resetRadioButtons(); // Reset radio buttons when the component mounts
  }, [sentUsername, retryPassword]);

  //request a password reset for user
  const resendPasswordResetForUser = async () => {
    setOtpSentAlert(false);
    try {
      setOtpSentAlert(true);
      const response = await requestPasswordReset(retrieve.email, retrieve.cidNumber, retrieve.isEmail, retrieve.isSms);
      setReceiveOTP(response);
    } catch (err) {
      setError(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Sorry. Something went wrong..' }));
    }
  };
  const handleOtpRequestForLoggedInUser = async () => {
    console.log('handleOtpRequestForLoggedInUser isUserLoggedIn', isUserLoggedIn);
    setError('');
    try {
      console.log('handleOtpRequestForLoggedInUser loginApiResponse', loginApiResponse);
      const email = loginApiResponse?.email ?? account?.email;
      const cid = loginApiResponse?.cid ?? account?.cid;
      if (!email || !cid) {
        setError(
          formatErrorMessage({
            id: 'missingData',
            defaultMessage: 'User email or customer ID is missing.',
          }),
        );
        return;
      }
      const response = await requestPasswordReset(email, cid, retrieve.isEmail, retrieve.isSms, isUserLoggedIn);
      console.log('handleOtpRequestForLoggedInUser requestPasswordReset response:::', response);
      setIsLoginOtpSent(true);
      setOtpResponse(response);
    } catch (err) {
      console.log('handleOtpRequestForLoggedInUser Catch Error:::', err);
      setError(
        formatErrorMessage({
          id: 'wentWrong',
          defaultMessage: 'Sorry. Something went wrong.',
        }),
      );
    }
  };

  useEffect(() => {
    if (receiveOTP?.passwordResetToken?.token) {
      setRetrieveUsername(false);
      setSendCode(true);
    }
  }, [receiveOTP]);

  const retrieveOTPcode = async () => {
    try {
      if (!resetUsername) {
        const otpResponsePassword = await updateCompareOTP(
          receivedOTP,
          receiveOTP?.passwordResetToken?.token,
          receiveOTP?.passwordResetToken?.tokenValidUntil,
          isUserLoggedIn,
        );
        if (otpResponsePassword.errorCode !== 500) {
          setUpdatePassword(otpResponsePassword);
          setRetryPassword(true);
          setError('');
        } else {
          setRetryPassword(false);
          setError(otpResponsePassword.body);
        }
      } else {
        const otpResponseUserName = await compareUserNameOTPWithToken(
          receivedOTP,
          userOtp?.userNameResetToken?.token,
          retrieve.email,
          userOtp?.userNameResetToken?.tokenValidUntil,
        );
        if (otpResponseUserName.errorCode !== 500) {
          setUpdateUsername(otpResponseUserName);
          setSentUsername(true);
          setError('');
        } else {
          setSentUsername(false);
          setError(otpResponseUserName.body);
        }
      }
    } catch (err) {
      setError(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Sorry. Something went wrong..' }));
    }
  };

  const authretrieveOTPcode = async () => {
    try {
      console.log('authretrieveOTPcode loginApiResponse:::', loginApiResponse, account, data);
      console.log('authretrieveOTPcode account:::', account);
      console.log('authretrieveOTPcode data:::', data);
      const availableAccount = loginApiResponse ?? account;
      const otpResponsePassword = await updateCompareOTP(
        receivedOTP,
        otpResponse?.passwordResetToken?.token,
        otpResponse?.passwordResetToken?.tokenValidUntil,
        isUserLoggedIn,
        availableAccount,
      );
      if (otpResponsePassword.errorCode !== 500) {
        console.log('authretrieveOTPcode otpResponsePassword:::', otpResponsePassword);
        console.log('authretrieveOTPcode account:::', availableAccount);
        if ('accountId' in availableAccount) {
          console.log(
            'authretrieveOTPcode accountId is available isProfileUpdate :::',
            availableAccount,
            isProfileUpdate,
            isPasswordChange,
          );
          await mutate('/action/account/getAccount', availableAccount);
          await mutate('/action/cart/getCart');
          loginCallBack();
          closeModal();
          setLoading(false);
          if (isProfileUpdate) {
            console.log('Calling updateAccountData:::' + typeof updateAccountData, updateAccountData);
            updateAccountData();
          }
          if (isPasswordChange) {
            console.log('Calling changeAccountPassword:::' + typeof changeAccountPassword, passwordChangeData);
            changeAccountPassword(passwordChangeData);
          }
          localStorage.setItem('lastLoginTime', Date.now().toString());
          localStorage.setItem('deviceId', deviceUuid);
          window?.monetateQ?.push(['trackData']);
        } else if ('errorCode' in availableAccount && 'body' in availableAccount && 'isLocked' in availableAccount) {
          const errorResponse = availableAccount as any;
          setAuthenticationError(true);
          setError(errorResponse?.body);
          setSignIn(false);
          setLoading(false);
          setIsButtonDisabled(true);
          setResErrorMsg(errorResponse?.body);
          if (isRememberMeSessionExpired) {
            logout(account?.accountId);
            router.push('/');
          }
        } else if ('errorCode' in availableAccount && 'body' in availableAccount) {
          const errorResponse = loginApiResponse as any;
          setAuthenticationError(true);
          setError(errorResponse?.body);
          setSignIn(false);
          setLoading(false);
          setResErrorMsg(errorResponse?.body);
        }
      } else {
        setRetryPassword(false);
        setError(otpResponsePassword.body);
      }
    } catch (err) {
      setError(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Sorry. Something went wrong..' }));
    }
  };

  useEffect(() => {
    if (updatePassword) {
      setRetryPassword(true);
      setSendCode(false);
      setError('');
    }
  }, [updatePassword]);

  useEffect(() => {
    if (updateUsername) {
      setSentUsername(true);
      setSendCode(false);
      setError('');
    }
  }, [updateUsername]);

  const newUserName = async () => {
    setOtpSentAlert(false);
    try {
      const userOtpResponse = await requestResetUsername(
        retrieve.email,
        retrieve.cidNumber,
        retrieve?.isEmail,
        retrieve?.isSms,
      );
      setOtpSentAlert(true);
      setUserOtp(userOtpResponse);
    } catch (err) {
      setError(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Sorry. Something went wrong..' }));
    }
  };
  useEffect(() => {
    if (userOtp?.userNameResetToken?.token) {
      setRetryPassword(false);
      setSendCode(true);
    }
  }, [userOtp]);

  const validatePassword = (newPassword) => {
    const mustDifferentFromUsername = newPassword !== receiveOTP?.passwordResetToken?.userName;
    const doesNotContainSpaces = !newPassword?.includes(' ');
    const hasMinimumLength = newPassword?.length >= 6;
    const hasMaximumLength = newPassword?.length <= 15;
    const hasUppercase = /[A-Z]/.test(newPassword);
    const hasDigit = /[0-9]/.test(newPassword);
    const allowedSpecialCharacters = `!^[]’'"@#$%&*()_+=|}{;:<,.>/?”`;
    let hasAllowedSpecialCharacters = false;
    for (const char of allowedSpecialCharacters) {
      if (newPassword?.includes(char)) {
        hasAllowedSpecialCharacters = true;
        break;
      }
    }
    return {
      mustDifferentFromUsername,
      doesNotContainSpaces,
      hasMinimumLength,
      hasMaximumLength,
      hasUppercase,
      hasDigit,
      hasAllowedSpecialCharacters,
    };
  };
  const isValid = () => {
    return (
      passwordValidation.mustDifferentFromUsername &&
      passwordValidation.doesNotContainSpaces &&
      passwordValidation.hasMinimumLength &&
      passwordValidation.hasMaximumLength &&
      passwordValidation.hasUppercase &&
      passwordValidation.hasDigit &&
      passwordValidation.hasAllowedSpecialCharacters
    );
  };
  const passwordValidation = validatePassword(newPassword);

  const newPasswordForUser = async () => {
    try {
      if (!isValid()) {
        setShowPasswordRequirements(true);
        return;
      }
      const response = await resetPassword(updatePassword?.token, newPassword);

      if (response.errorCode !== 500) {
        setSuccess(response.body);
        setTimeout(() => {
          backToLogin();
        }, 2000);
      } else {
        setErrMessage(response.body);
        if (!newPassword) {
          setErrMessage('');
        }
      }

      setTimeout(() => {
        setShowPasswordRequirements(false);
        setSuccess('');
        setResErrorMsg('');
      }, 2000);
    } catch (err) {
      setError(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Sorry. Something went wrong..' }));
    }
  };
  //to check maximum login Attempts
  const [resErrorMsg, setResErrorMsg] = useState(null);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  const loginUser = async () => {
    try {
      console.log('LoginModalContent loginUser() Calling login with data:::', data);
      const response = await login(
        data.username.toLowerCase(),
        data.password,
        isRememberMeSessionExpired ? true : data.rememberMe,
        undefined,
        data.is2FA,
      );
      console.log('LoginModalContent loginUser() login response:::', response);
      setLoginApiResponse(response);
      const isLoggedInWithinTime = checkLoginStatus();
      console.log('LoginModalContent loginUser() isLoggedInWithinTime:::', isLoggedInWithinTime);
      const is2FARequired = 'is2FARequired' in response ? response.is2FARequired : false;
      console.log('LoginModalContent loginUser() customer is2FARequired:::', is2FARequired);
      const isSameDevice = checkDeviceMatch();
      console.log('LoginModalContent loginUser() isSameDevice:::', isSameDevice);
      if ('accountId' in response && is2FARequired && !isLoggedInWithinTime && !isSameDevice) {
        console.log(
          'LoginModalContent loginUser() 2FA Required:::',
          'accountId' in response,
          is2FARequired,
          isSameDevice,
          isLoggedInWithinTime,
        );
        setLoading(false);
        setIsUserLoggedIn(true);
      } else {
        console.log(
          'LoginModalContent loginUser() 2FA Not Required:::',
          'accountId' in response,
          is2FARequired,
          isSameDevice,
          isLoggedInWithinTime,
        );
        if ('accountId' in response) {
          console.log(
            'LoginModalContent loginUser() 2FA Not Required[Good Login Response with accountId]:::',
            'accountId' in response,
            is2FARequired,
            isSameDevice,
            isLoggedInWithinTime,
          );
          await loginSetAccountToSession(response);
          await mutate('/action/account/getAccount', response);
          await mutate('/action/cart/getCart');
          loginCallBack();
          closeModal();
          setLoading(false);
          window?.monetateQ?.push(['trackData']);
          const loginData = {
            form_name: GA_SIGN_IN,
            content_id: GA_ACCOUNT,
          };
          logEventToDataLayer(GA_LOGIN, loginData);
        } else if ('errorCode' in response && 'body' in response && 'isLocked' in response) {
          console.log(
            'LoginModalContent loginUser() 2FA Not Required[adood Login Response without accountId (isLocked)]:::',
            'accountId' in response,
            'errorCode' in response,
            'body' in response,
            'isLocked' in response,
            is2FARequired,
            isSameDevice,
            isLoggedInWithinTime,
          );
          const errorResponse = response as any;
          setAuthenticationError(true);
          setError(errorResponse?.body);
          setSignIn(false);
          setLoading(false);
          setIsButtonDisabled(true);
          setResErrorMsg(errorResponse?.body);
          if (isRememberMeSessionExpired) {
            logout(account?.accountId);
            router.push('/');
          }
        } else if ('errorCode' in response && 'body' in response) {
          console.log(
            'LoginModalContent loginUser() 2FA Not Required[adood Login Response without accountId]:::',
            'accountId' in response,
            'errorCode' in response,
            'body' in response,
            'isLocked' in response,
            is2FARequired,
            isSameDevice,
            isLoggedInWithinTime,
          );
          const errorResponse = response as any;
          setAuthenticationError(true);
          setError(errorResponse?.body);
          setSignIn(false);
          setLoading(false);
          setResErrorMsg(errorResponse?.body);
        }
      }
    } catch (err) {
      setAuthenticationError(true);
      setSignIn(false);
      setLoading(false);
      setResErrorMsg(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Sorry. Something went wrong..' }));
      setError(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Sorry. Something went wrong..' }));
    }
  };

  const checkLoginStatus = (): boolean => {
    // Retrieve the timestamp from localStorage
    const lastLoginTime = localStorage.getItem('lastLoginTime');
    console.log(`checkLoginStatus lastLoginTime: ${lastLoginTime}`);
    if (lastLoginTime) {
      try {
        // Parse the timestamp as a number and create a Date object
        const storedLoginTime = new Date(Number(lastLoginTime));
        // Validate if the date object is valid
        if (isNaN(storedLoginTime.getTime())) {
          console.error('Invalid date format in localStorage:', lastLoginTime);
          return false;
        }
        const currentDate = new Date();
        const timeDifference = currentDate.getTime() - storedLoginTime.getTime();
        const dayDifference = timeDifference / (1000 * 60 * 60 * 24);
        console.log('Day Difference:', dayDifference);
        const daysToLive = projectConfiguration.twoFaTtlDays || 90; // Default to 90 days if not set in project configuration
        console.log('Days To Live:', daysToLive, projectConfiguration.twoFaTtlDays);
        if (dayDifference <= daysToLive) {
          console.log('checkLoginStatus return true.');
          return true;
        }
      } catch (error) {
        console.error('Error processing last login time:', error);
        // Remove invalid or expired last login time
        localStorage.removeItem('lastLoginTime');
        console.log('checkLoginStatus return false.');
        return false;
      }
    } else {
      return false;
    }
  };

  //handle text iput change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'rememberMe') {
      if (isButtonDisabled) {
        setLoading(false);
        setUsernameError('');
        setPasswordError('');
      }
    } else {
      setIsButtonDisabled(false);
      setAuthenticationError(false);
      setLoading(false);
      setError('');
      setResErrorMsg('');
      setUsernameError('');
      setPasswordError('');
    }
    setData({ ...data, [e.target.name]: e.target.name == 'rememberMe' ? e.target.checked : e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isErrorPresent = false;
    if (data.username.length == 0) {
      setUsernameError('Username is required.');
      isErrorPresent = true;
    }
    if (data.password.length == 0) {
      setPasswordError('Password is required.');
      isErrorPresent = true;
    }
    if (!isErrorPresent) {
      setLoading(true);
      setAuthenticationError(false);
      setError('');
      setResErrorMsg(null);
      loginUser();
    }
  };

  const handlekeypress = async (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      document.getElementById('signinbtn').click();
    }
  };

  useEffect(() => {
    if (handleForgotCredentials && handleForgotCredentials?.isForgotUserName) {
      toForgotUsername();
    } else if (handleForgotCredentials && handleForgotCredentials?.isForgotPassword) {
      toForgotPassword();
    }
  }, [handleForgotCredentials]);

  const handleSendCodeClick = () => {
    setError('');
    setSendCodeClicked(true);

    setTimeout(() => {
      !resetUsername ? resendPasswordResetForUser() : newUserName();
      setVerificationCodeSent(true);
    }, 2000);
  };

  return (
    <>
      <section ref={sectionRef}>
        <section aria-labelledby="Sign In Modal" className="modalHeader relative">
          {!forgotUsername && !retrieveUsername && !sendCode && !sentUsername && !retryPassword && !isUserLoggedIn && (
            <section className={isRememberMeSessionExpired ? 'flex flex-col' : ''}>
              <div className="mt-5 mb-6">
                {isRememberMeSessionExpired ? (
                  <h3 className="text-[18px] font-semibold text-black-900">{`Hi, ${account?.firstName}!`}</h3>
                ) : (
                  <h3 className="text-[18px] font-semibold text-black-900">
                    {formatAccountMessage({
                      id: 'welcomeSignIN',
                      defaultMessage: 'Welcome! Sign In',
                    })}
                  </h3>
                )}
                <button
                  type="button"
                  className="absolute right-0 top-2 outline-none focus:outline-none"
                  onClick={closeModal}
                  aria-label="Close Sign In Modal"
                >
                  <GrClose />
                </button>
              </div>
              {isRememberMeSessionExpired ? (
                <p className="mb-0.5 text-affes-sm">
                  {formatAccountMessage({
                    id: 'reEnterAlertMsg',
                    defaultMessage: 'To modify your account preferences, please re-enter your password.',
                  })}
                </p>
              ) : (
                <></>
              )}
            </section>
          )}
        </section>
        {resErrorMsg && (
          <section aria-live="polite" className="mb-3 text-affes-sm text-red-900">
            <span dangerouslySetInnerHTML={{ __html: resErrorMsg }}></span>
          </section>
        )}
        {authenticationError && (
          <section>
            <button
              type="button"
              onClick={() => setShowDelayedRecordsMsg(!showDelayedRecordsMsg)}
              className="text-affes-sm font-bold text-[#043C63]"
              aria-expanded={showDelayedRecordsMsg}
              aria-controls="delayedRecordsMsg"
            >
              <ChevronRightIcon
                className={`${
                  showDelayedRecordsMsg ? 'rotate-90 transform' : ''
                } inline h-5 w-5 text-[#043C63] transition`}
              />
              <span>Retired, Discharged, or a Veteran?</span>
            </button>
            <Transition
              show={showDelayedRecordsMsg}
              enter="transition duration-150 ease-out"
              enterFrom="transform scale-y-95 opacity-0"
              enterTo="transform scale-y-100 opacity-100"
              leave="transition duration-100 ease-out"
              leaveFrom="transform scale-y-100 opacity-100"
              leaveTo="transform scale-y-95 opacity-0"
              id="delayedRecordsMsg"
              aria-labelledby="delayedRecordsMsgLabel"
            >
              <p className="text-affes-sm font-bold text-[#043C63]" id="delayedRecordsMsgLabel">
                {formatAccountMessage({
                  id: 'new.Retiree.copy',
                  defaultMessage:
                    'If you are a new retiree or honorably discharged veteran, there may be a delay in AAFES receiving your updated records. If you have an issue logging into your AAFES account, please allow at least 72 hours for your account to activate. If the issue continues, contact Customer Service.',
                })}
              </p>
            </Transition>
          </section>
        )}
        {isButtonDisabled && (
          <p className="text-sm text-red-900" aria-live="assertive">
            {Error}
          </p>
        )}
        {loading && (
          <section className="flex justify-center" aria-busy="true" aria-live="assertive">
            <img src={exchangeLoader.src} alt="Loader image" />
          </section>
        )}

        {!isUserLoggedIn && !forgotUsername && !retrieveUsername && !sendCode && !sentUsername && !retryPassword && (
          <DefaultLoginPage
            usernameError={usernameError}
            data={data}
            handleChange={handleChange}
            handlekeypress={handlekeypress}
            inputRef={inputRef}
            handleInputFocus={handleInputFocus}
            handleInputBlur={handleInputBlur}
            closeModal={closeModal}
            passwordShown={passwordShown}
            passwordError={passwordError}
            isRememberMeSessionExpired={isRememberMeSessionExpired}
            togglePassword={togglePassword}
            continueAsGuestEligible={continueAsGuestEligible}
            isButtonDisabled={isButtonDisabled}
            toForgotPassword={toForgotPassword}
            toForgotUsername={toForgotUsername}
            signIn={signIn}
            handleSubmit={handleSubmit}
            loading={loading}
          />
        )}
        {isUserLoggedIn && !isLoginOtpSent && (
          <RetrieveUsername
            resetUsername={resetUsername}
            toForgotPassword={toForgotPassword}
            toForgotUsername={toForgotUsername}
            retrieve={retrieve}
            retrievePasswordClick={retrievePasswordClick}
            inputRef={inputRef}
            handleInputFocus={handleInputFocus}
            handleInputBlur={handleInputBlur}
            maskPhoneNumber={maskPhoneNumber}
            customer={customer}
            maskEmail={maskEmail}
            resendPasswordResetForUser={resendPasswordResetForUser}
            newUserName={newUserName}
            resetRadioButtons={resetRadioButtons}
            setReceivedOTP={setReceivedOTP}
            isUserLoggedIn={isUserLoggedIn}
            loginApiResponse={loginApiResponse}
            handleOtpRequestForLoggedInUser={handleOtpRequestForLoggedInUser}
            handleLogoutAndCloseModal={handleLogoutAndCloseModal}
            account={account}
          />
        )}
        {isUserLoggedIn && isLoginOtpSent && (
          <SendResetCode
            resetUsername={resetUsername}
            toForgotUsername={toForgotUsername}
            otpSentAlert={otpSentAlert}
            retrieve={retrieve}
            error={error}
            verificationCodeSent={verificationCodeSent}
            sendCodeClicked={sendCodeClicked}
            receivedOTP={receivedOTP}
            setReceivedOTP={setReceivedOTP}
            handleSendCodeClick={handleSendCodeClick}
            inputRef={inputRef}
            handleInputFocus={handleInputFocus}
            handleInputBlur={handleInputBlur}
            resetRadioButtons={resetRadioButtons}
            retrieveOTPcode={retrieveOTPcode}
            toForgotPassword={toForgotPassword}
            isUserLoggedIn={isUserLoggedIn}
            handleLogoutAndCloseModal={handleLogoutAndCloseModal}
            authretrieveOTPcode={authretrieveOTPcode}
            handleOtpRequestForLoggedInUser={handleOtpRequestForLoggedInUser}
          />
        )}

        {forgotUsername && !retrieveUsername && !sendCode && !sentUsername && (
          <ForgotUserName
            resetRadioButtons={resetRadioButtons}
            handleVerifySSN={handleVerifySSN}
            setReceivedOTP={setReceivedOTP}
            loading={loading}
            isEmpty={isEmpty}
            setShowSsn={setShowSsn}
            resetUsername={resetUsername}
            invalidSsn={invalidSsn}
            errorInvalidSsn={errorInvalidSsn}
            customer={customer}
            backToLogin={backToLogin}
            setInformationRequired={setInformationRequired}
            showSsn={showSsn}
            customerData={customerData}
            handleNameChange={handleNameChange}
            inputRef={inputRef}
            handleInputFocus={handleInputFocus}
            handleInputBlur={handleInputBlur}
          />
        )}

        {retrieveUsername && !sendCode && !sentUsername && !retryPassword && (
          <RetrieveUsername
            resetUsername={resetUsername}
            toForgotPassword={toForgotPassword}
            toForgotUsername={toForgotUsername}
            retrieve={retrieve}
            retrievePasswordClick={retrievePasswordClick}
            inputRef={inputRef}
            handleInputFocus={handleInputFocus}
            handleInputBlur={handleInputBlur}
            maskPhoneNumber={maskPhoneNumber}
            customer={customer}
            maskEmail={maskEmail}
            resendPasswordResetForUser={resendPasswordResetForUser}
            newUserName={newUserName}
            resetRadioButtons={resetRadioButtons}
            setReceivedOTP={setReceivedOTP}
            isUserLoggedIn={isUserLoggedIn}
            loginApiResponse={loginApiResponse}
            handleOtpRequestForLoggedInUser={handleOtpRequestForLoggedInUser}
            handleLogoutAndCloseModal={handleLogoutAndCloseModal}
            account={account}
          />
        )}

        {sendCode && !sentUsername && (
          <SendResetCode
            resetUsername={resetUsername}
            toForgotUsername={toForgotUsername}
            otpSentAlert={otpSentAlert}
            retrieve={retrieve}
            error={error}
            verificationCodeSent={verificationCodeSent}
            sendCodeClicked={sendCodeClicked}
            receivedOTP={receivedOTP}
            setReceivedOTP={setReceivedOTP}
            handleSendCodeClick={handleSendCodeClick}
            inputRef={inputRef}
            handleInputFocus={handleInputFocus}
            handleInputBlur={handleInputBlur}
            resetRadioButtons={resetRadioButtons}
            retrieveOTPcode={retrieveOTPcode}
            toForgotPassword={toForgotPassword}
            isUserLoggedIn={isUserLoggedIn}
            handleLogoutAndCloseModal={handleLogoutAndCloseModal}
            authretrieveOTPcode={authretrieveOTPcode}
            handleOtpRequestForLoggedInUser={handleOtpRequestForLoggedInUser}
          />
        )}

        {retryPassword && !forgotUsername && !retrieveUsername && !sendCode && !sentUsername && (
          <RetryPassword
            toRetrieveUsername={toRetrieveUsername}
            success={success}
            error={error}
            errMessage={errMessage}
            newPassword={newPassword}
            showPassword={showPassword}
            handleResetPassword={handleResetPassword}
            inputRef={inputRef}
            handleInputFocus={handleInputFocus}
            handleInputBlur={handleInputBlur}
            newPasswordForUser={newPasswordForUser}
            newPasswordError={newPasswordError}
            errorMessage={errorMessage}
            setShowPassword={setShowPassword}
            passwordRequirements={passwordRequirements}
            receiveOTP={receiveOTP}
          />
        )}

        {sentUsername && !retryPassword && <SentUserName resetUsername={resetUsername} backToLogin={backToLogin} />}

        {informationRequired && (
          <InformationRequired informationRequired={informationRequired} closeModal={handleInformationRequired} />
        )}
      </section>
    </>
  );
}

export default LoginModalContent;
